import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { UserSettingsService } from 'catalean-authentication';
import { environment } from 'src/environments/environment';
import { ApplicationGroup } from '../models/application-group';

@Injectable({
	providedIn: 'root',
})
export class ApplicationGroupService {
	private userSettingService = inject(UserSettingsService);
	private http = inject(HttpClient);

	fetchUserGroups() {
		const params = {
			organizationUUID: environment.organizationUUID,
			'members.uuid': this.userSettingService.getUserUUID(),
		};

		return this.http.get<ApplicationGroup[]>(environment.applicationGroupUrl, { params });
	}
}
