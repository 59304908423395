import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { Observable } from "rxjs";
import { ProductManagerService } from "catalean-provider";

@Injectable({
  providedIn: "root",
})
export class LoadGuard implements CanActivate {
  constructor(private productService: ProductManagerService, private router: Router) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (!!this.productService.Products.length) {
      return true;
    }
    this.router.navigate([""]);
  }
}
