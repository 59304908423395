import { DekaIntegration } from 'src/app/models/deka-integration';
import { inject, Injectable } from '@angular/core';
import { CataleanConfigService } from 'catalean-configuration';
import { CataleanAuthenticationService } from 'catalean-authentication';
import { combineLatest, filter, map, switchMap } from 'rxjs';
import { ApplicationGroupService } from './application-group.service';
import { ApplicationGroup } from '../models/application-group';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class ApplicationGroupLocaleService {
  private integrationService = inject(CataleanConfigService<DekaIntegration>);
  private applicationGroupService = inject(ApplicationGroupService);
  private userService = inject(CataleanAuthenticationService);

  availableLocales$ = combineLatest<boolean[]>([this.userService.isDoneLoading$, this.userService.isAuthenticated$])
  .pipe(
    filter(value => value.every(Boolean)),
    switchMap(() => combineLatest<[DekaIntegration, ApplicationGroup[]]>([this.integrationService.getCatalean(), this.applicationGroupService.fetchUserGroups()])),
    map(([integration, groups]) => {
      const newAvailableLocales: string[] = [];

      if(integration.applicationUserGroupLocaleMap && groups.length) {
        let isMemberOfGroupWithNoConfig = true;
        for(const group of groups) {
          const localeMap = integration.applicationUserGroupLocaleMap.find(localeMap => localeMap.applicationGroupUUID === group.uuid);
          if(localeMap) {
            isMemberOfGroupWithNoConfig = false;
            if(localeMap.availableLocales?.length) {
              newAvailableLocales.push(...localeMap.availableLocales.map(localeValue => localeValue.locale))
            }
          }
        }

        if(!isMemberOfGroupWithNoConfig) {
          return Array.from(new Set(newAvailableLocales)); //rimuovo duplicati
        }
      }

      //ritorno tutti i locale in due casi:
      // - non esiste questa config nell'integrazione
      // - l'utente appartiene a dei gruppi che non hanno una configurazione
      return environment.availableLocales;
    })
  )
}
