import { inject, Injectable } from '@angular/core';
import { CataleanConfigService } from 'catalean-configuration';
import { CataleanLocalizationService } from 'catalean-localization';
import { combineLatest, Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { DekaIntegration } from '../models/deka-integration';
import { FilterManagerService } from 'catalean-provider';
import { Filter } from 'catalean-models';

/**
	* Servizio che calcola quali sono le metro disponibili a seconda della lingua
	* La configurazione viene salvata nell'integrazione Catalean
	* Di default, se la metro non ha una configurazione per i locale
	* Viene aggiunta lo stesso
	*/
@Injectable({
	providedIn: 'root',
})
export class AvailableFilterMetroByLocaleService {
	private configService = inject(CataleanConfigService<DekaIntegration>);
	private localeService = inject(CataleanLocalizationService);
	private metroService = inject(FilterManagerService);
	private _actualLocaleAvailableMetroFilter$: Observable<string[]> = combineLatest<[DekaIntegration, string, Filter[]]>([
		this.configService.getCatalean(),
		this.localeService.ObserveActiveLang,
		this.metroService.getRootFilterChildren(),
	]).pipe(
		map(([cataleanIntegration, activeLanguage, metroFilters]) => {
			const availableMetroFilters: string[] = [];
			if (cataleanIntegration.metroFilterLocaleMap) {
				for(const filter of metroFilters) {
					const metroLocaleMap = cataleanIntegration.metroFilterLocaleMap.find(metroMap => metroMap.metroFilterUUID === filter.featureId);
					if(metroLocaleMap) {
						if(metroLocaleMap.availableLocales?.find(localeValue => localeValue.locale === activeLanguage)) {
							availableMetroFilters.push(filter.featureId);
						}
					} else {
						availableMetroFilters.push(filter.featureId);
					}
				}

				return availableMetroFilters;
			}

			return metroFilters.map(filter => filter.featureId);
		}),
		shareReplay({ refCount: true, bufferSize: 1 })
	);

	get actualLocaleAvailableMetroFilter$(): Observable<string[]> {
		return this._actualLocaleAvailableMetroFilter$;
	}
}
