import { Pipe, PipeTransform, inject } from '@angular/core';
import { DeviceResourcesService } from 'catalean-local-files';

@Pipe({
  name: 'toLocalUrl',
  standalone: true,
})
export class ToLocalUrlPipe implements PipeTransform {

  localFileManager = inject(DeviceResourcesService)

  transform(url: string | {url: string, previewURL?: string}, startDownloadIfNeeded = true, sanitized = false, convertIonic = true): Promise<string> {
    return this.localFileManager.getResourceUrl(url,startDownloadIfNeeded,sanitized,convertIonic) as Promise<string>;
  }

}
