import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'smart-icon',
  templateUrl: 'smart-icon.html',
  styleUrls: ['./smart-icon.scss'],
  standalone: true,
  imports: [CommonModule],
})
export class SmartIconComponent {
  @Input('class') additionalClass: string;
  @Input('color') color: string;
  @Input('name') name: string;

  constructor() {}

  getClassList() {
    let classList = 'icon';
    if (this.additionalClass) {
      classList += ' ' + this.additionalClass;
    }
    return classList;
  }
}
