import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginGuard } from './guards/login.guard';
import { AuthGuard } from './guards/auth.guard';
import { LoadGuard } from './guards/load.guard';

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  {
    path: "login",
    canActivate: [LoginGuard],
    loadChildren: () => import("./pages/login/login.module").then((m) => m.LoginPageModule),
  },
  {
    path: "metro",
    canActivate: [AuthGuard, LoadGuard],

    loadChildren: () => import("./pages/metro/metro.module").then((m) => m.MetroPageModule),
  },
  {
    path: "product-grid",
    canActivate: [AuthGuard, LoadGuard],
    loadChildren: () => import("./pages/product-grid/product-grid.module").then((m) => m.ProductGridPageModule),
  },
  {
    path: 'product-detail',
    canActivate: [AuthGuard, LoadGuard],
    loadChildren: () => import('./pages/product-detail/product-detail.module').then((m) => m.ProductDetailPageModule),
  },
  {
    path: 'search-results/:text',
    canActivate: [AuthGuard, LoadGuard],
    loadChildren: () => import('./pages/search-page/search-results.module').then((m) => m.SearchResultsPageModule),
  },
  { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { enableTracing: false })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
