import { Component, NgZone, OnInit, OnDestroy, DestroyRef, inject } from '@angular/core';
import { MenuController, NavController } from '@ionic/angular';
import { CataleanLocalizationService } from 'catalean-localization';
import { environment } from '../environments/environment';
import { Subject, Subscription, combineLatest, map } from 'rxjs';
import { DeviceResourcesService } from 'catalean-local-files';
import { CataleanAuthenticationService } from 'catalean-authentication';
import { FilterManagerService } from 'catalean-provider';
import { Filter } from 'catalean-models';
import { App } from '@capacitor/app';
import { Browser } from '@capacitor/browser';
import { Capacitor } from '@capacitor/core';
import { Router } from '@angular/router';
import { CurrentPlatformService } from './services/current-platform.service';
import { ApplicationGroupLocaleService } from './services/application-group-locale.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AvailableFilterMetroByLocaleService } from './services/available-metro-filter-by-locale.service';

const env = environment;

declare let window: any;
window.pdfWorkerSrc = './assets/pdf.worker.min.js';
@Component({
	selector: 'app-root',
	templateUrl: 'app.component.html',
	styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
	public readonly VERSION_PREFIX: string = 'v';
	public version: string = '';
	public openMenuSubject = new Subject<void>();
	isMobile$ = this.platform.isPhone;
	private wasDownloadPaused = false;
	private resumeSub: Subscription;
	private pauseSub: Subscription;
	private destroyRef = inject(DestroyRef);
	private availableLocaleFilterMetroService = inject(AvailableFilterMetroByLocaleService);

	rootFilterChildren$ = combineLatest([this.filterService.getRootFilterChildren(), this.availableLocaleFilterMetroService.actualLocaleAvailableMetroFilter$])
		.pipe(
			map(([filters, availableLocaleFilters]) => {
				return filters.filter((filter) => {
					return availableLocaleFilters.includes(filter.featureId);
				});
			})
		);
	activeFilter$ = this.filterService.getSelectedMetroFilter();
	rootFilter$ = combineLatest([this.filterService.getRootFilter(), this.availableLocaleFilterMetroService.actualLocaleAvailableMetroFilter$])
		.pipe(
			map(([rootFilter, availableLocaleFilters]) => {
				if(rootFilter?.children) {
					rootFilter.children = rootFilter.children.filter(child => availableLocaleFilters.includes(child.featureId))
				}
				return rootFilter
		})
	);

	constructor(
		public platform: CurrentPlatformService,
		private menuController: MenuController,
		public localizationService: CataleanLocalizationService,
		private navCtrl: NavController,
		private ngZone: NgZone,
		private deviceResourcesService: DeviceResourcesService,
		private authService: CataleanAuthenticationService,
		private filterService: FilterManagerService,
		private router: Router,
		private applicationGroupLocaleService: ApplicationGroupLocaleService
	) {
		this.initializeApp();
	}

	initializeApp() {
		this.platform.ready().then(() => {
			// devo settarlo inizialmente perchè altrimenti
			// la chiamata al catalean cache per il current version si rompe
			this.localizationService.setDefaultLang(env.defaultLocale);

			//devo settarlo altrimenti non vedo locale nella login
			this.localizationService.AvailableLocales = env.availableLocales;
			this.applicationGroupLocaleService.availableLocales$
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe({
				next: (availableLocales) => {
					this.localizationService.AvailableLocales = availableLocales;

					if(!availableLocales.includes(env.defaultLocale) && availableLocales.length > 0) {
						this.localizationService.setDefaultLang(availableLocales[0])
					}

					if(!availableLocales.includes(this.localizationService.ActiveLanguage) && availableLocales.length > 0) {
						this.localizationService.setActiveLanguage(availableLocales[0]);
					}
				}
			});

			// TODO BETTER PERMISSION MANAGMENT
			// could be done with something like a blocking loading page like in smartcommunity
		});
	}

	ngOnDestroy(): void {
		this.resumeSub?.unsubscribe();
		this.pauseSub?.unsubscribe();
	}

	ngOnInit() {
		App.addListener('appUrlOpen', (value) => {
			if (value.url.includes(environment.appId + '://')) {
				console.log('redirecting from universal link: ', value.url);
				if (Capacitor.getPlatform() === 'android') {
					location.href = 'http://localhost/' + value.url.replace(environment.appId + '://', '');
				}
				if (Capacitor.getPlatform() === 'ios') {
					location.href = 'capacitor://localhost/' + value.url.replace(environment.appId + '://', '');
					Browser.close();
				}
			} else {
				this.router.navigate(['initializer']);
			}
		});

		if (this.platform.is('mobile')) {
			this.platform.ready().then(() => {
				App.getInfo()
					.then(({ version }) => {
						this.version = this.VERSION_PREFIX + version;
					})
					.catch((err) => {
						console.log(err);
						this.version = this.VERSION_PREFIX + environment.version;
					});
			});
		} else {
			this.version = this.VERSION_PREFIX + environment.version;
		}

		if (this.platform.is('cordova')) {
			this.resumeSub = this.platform.resume.subscribe(() => {
				if (!this.wasDownloadPaused) {
					this.ngZone.run(() => {
						this.deviceResourcesService.doDownloadRemoteResources();
					});
				}
			});
			this.pauseSub = this.platform.pause.subscribe(() => {
				this.wasDownloadPaused = this.deviceResourcesService.StopDownload;
				this.deviceResourcesService.StopDownload = true;
			});
		}
	}

	goTo(filter: Filter) {
		this.navCtrl.navigateRoot(['metro']).then(() => {
			if (!filter['isRoot']) {
				this.filterService.setSelectedMetroFilter(filter);
				const horizontalFilter = filter.children.find((child) => child.align === 'horizontal');
				if (horizontalFilter) {
					this.filterService.setHorizontalFilter(horizontalFilter);
				}

				this.navCtrl.navigateForward(['product-grid']);
			}

			window.setTimeout(() => this.menuController.close('catalean-menu'), 600);
			return;
		});
	}

	async logout() {
		this.deviceResourcesService.StopDownload = true;
		await this.authService.logout();
		if (environment.logoutDataRetentionMode === 3) {
			await this.deviceResourcesService.deleteAllAssets();
		}
	}

	onMenuOpen() {
		this.openMenuSubject.next();
	}
}
